<template>
  <div>
    <div>
      <section class="w-100">
        <section>
          <h5 class="mb-0">Selecione até 5 seguros para adicionar</h5>
          <span>{{ selecteds.length }} selecionados</span>
        </section>

        <div class="input-search w-100 p-0 my-2">
          <b-form-input @input="handleSearch" v-model="search" placeholder="Digite para pesquisar" class="mb-2" />
          <b-button variant="light" class="px-2 d-flex align-items-center absolute icon-action">
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
      </section>
      <cards-list grid="3" responsive="1" gap="12" class="content-security-modal">
        <b-card no-body @click="handleSelect(insurance)" class="pointer card-security p-3" :class="[
          selecteds.includes(insurance) && 'selected',
          selecteds.length === 5 &&
          !selecteds.includes(insurance) &&
          'disabled',
        ]" v-for="(insurance, index) in items" :key="index">
          <span class="ball-selection" :class="selecteds.includes(insurance) && 'selected'" />
          <div class="mr-md-4">
            <span class="d-block title" v-if="insurance && insurance.insurance && insurance.insurance_company
              ">
              {{ insurance.insurance.insurance_company.social_reason }}
            </span>
            <small class="text-muted">{{ insurance.situation }}</small>
          </div>
          <div>
            <table class="mb-3">
              <tr>
                <td><strong>Término da vigência:</strong></td>
                <td class="pl-2">{{ insurance.validity | date }}</td>
              </tr>
              <tr>
                <td><strong>Cobertura:</strong></td>
                <td class="pl-2">{{ insurance.coverage | money }}</td>
              </tr>
            </table>
          </div>
        </b-card>
      </cards-list>
      <div class="d-flex justify-content-end w-100 mt-3">
        <b-button variant="primary" @click="sendSelecteds()" :disabled="selecteds && selecteds.length === 0">
          Salvar seleção
        </b-button>
      </div>
    </div>
    <!-- <div v-else class="text-center">
      <img
        src="@/assets/images/insurance.png"
        class="d-block mx-auto mb-3"
        height="100"
      />
      <p>Não há seguros cadastrados para seleção</p>
      <router-link to="/my-enterprise/legal/insurance">
        <b-button>Adicionar seguros</b-button>
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [listPaginated],
  data() {
    return {
      action_name: "insurance_providers",
      menus: [],
      params: {},
      empty: true,
      selecteds: [],
      items: ["1", "2"],
    };
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user"]),
  },
  methods: {
    ...mapActions(["get_all_insurance_providers"]),
    sendSelecteds() {
      this.$emit("close", this.selecteds);
    },
    handleSelect(sk) {
      if (this.selecteds.includes(sk)) {
        this.selecteds = this.selecteds.filter((item) => item !== sk);
        return;
      }
      if (this.selecteds.length === 5) {
        return;
      }
      this.selecteds.push(sk);
    },
  },
};
</script>
<style lang="scss">
.content-security-modal {
  .card-security {
    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }

    &.selected {
      border-color: $primary;

      .title {
        color: $primary;
      }
    }
  }
}
</style>
